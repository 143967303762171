body {
    font-family: 'Arial', sans-serif;
}
.container {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
}

.section-1, .section-3 {
    background-color: #ffffff;
}

.section-2, .section-4 {
    background-color: #ffffff;
}

.img-fluid {
    max-width: 50%;
    height: auto;
}

.btn-primary {
    background-color: #336699;
    border-color: #336699;
}  

.emma-picture {
    border-radius: 50%;
    margin-right: 20px;
}

.chat-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

#example-chat-picture {
    width: 300px;
    border-radius: 5%;
    margin: auto;
    display: flex;
    margin-top: 20px;
    
}
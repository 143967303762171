body {
  background-color: #f0f2f5;
  color: #3b4a54;
}

.livechat {
  max-width: 1600px;
  max-height: 1000px; /* Set the maximum height to 1000px */
  height: 100vh; /* Set the height to 100% of the viewport height */
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.user-list {
  width: 20%;
  min-width: 100px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
}

.user-list-header {
  background-color: #e9e9e9;
  flex: 0 0 96px;
  font-size: 20px;
  font-weight: bold;
}

.user-list-search {
  flex: 0 0 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #c3c3c3;
}

.user-list-search-box {
  background-color: #e9e9e9;
  width: 95%;
  height: 80%;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  font-size: 16px;
  padding: 0 10px;
}

.user-list-body {
  flex: 1;
  overflow-y: auto;
}

.user-list-item {
  height: 100px;
  /* border-top: 1px solid #858585;
  border-bottom: 1px solid #c3c3c3; */
  font-size: 24;
  transition: background-color 0.1s ease; /* Add transition for smooth effect */
}

.user-list-item:hover {
  background-color: #e9e9e9;
}

.selected-user {
  background-color: #858585;
}

.phone-number {
  font-size: 24px;
  padding: 10px 10px 0px 10px;
  margin: 0;
}

.user-info {
  font-size: 16px;
  padding: 0 10px 10px 10px;
  margin: 0;
  align-content: top;
}

@media only screen and (max-width: 767px) {
  /* CSS for mobile devices */
  .user-list-item {
    height: 40px;
    border-top: 1px solid #858585;
  }
  .phone-number {
    font-size: 12px;
  }
  .user-info {
    font-size: 0px;
  }
}

.user-chat-window {
  background-color: #fff;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.active-user-info {
  width: 100%;
  flex: 0 0 96px;
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 32px;
  
}

.chats-window {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  background-image: url('../img/bg.jpg');
}

.send-chat-window {
  width: 100%;
  flex: 0 0 96px;
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
}

.input-field {
  flex-grow: 1;
}

.button-group {
  display: flex;
  flex-direction: column;
  margin: 16px;
}

.send-button {
  margin-bottom: 8px;
}

.chat-bubble {
    max-width: 60%;
    margin: 10px;
    background-color: #ffffff;
    border-radius: 20px 20px 20px 0;
    shadow: #0b141a;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);    
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: auto;
    margin-left: 20px;
    padding: 0 10px;
  }
  
.chat-bubble__left {
  max-width: 60%;
  align-items: flex-start;
  background-color: #59f899;
  shadow: #0b141a;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px 20px 0px 20px;
  margin-left: auto;
  margin-right: 20px;
  padding: 0 10px;
}

.chat-time {
  font-size: 12px;
  margin: 0px 0px 0px 10px;
  text-align: left;
}

.chat-username {
  font-weight: bold;
  margin: 5px 0px 0px 10px;
}

.chat-message {
  font-style: italic;
  margin: 5px 0px 10px 10px;
  text-align: left;
}

.chat-image {
  max-width: 30%;
  border-radius: 10px;
  margin: 5px 0px 15px 5px;
}